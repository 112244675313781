








































































import {Vue, Component} from 'vue-property-decorator';
import {
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BCardGroup,
    BCardHeader,
    BCardText,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol
} from 'bootstrap-vue';

@Component({
    components: {
        BCard,
        BCardText,
        BCardHeader,
        BListGroup,
        BListGroupItem,
        BCardBody,
        BCardTitle,
        BCardFooter,
        BButton,
        BCardGroup,
        BRow,
        BCol
    }
})
export default class ChooseStartingPoint extends Vue {


}
